Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  
  exports.footerDesc = "Jeddah House of Comerce is the ultimate compliance-centric Recovery Management Solution (RMS) for creditors, debt buyers and collection agencies."
  exports.OurSite = "Our Site";
  exports.Creditors = "Creditors";
  exports.debutBuyers = "Debut Buyers";
  exports.Buyers = "Buyers";
  exports.industries = "Industries";
  exports.sellers = "Sellers";
  exports.Contract = "Contract";
  exports.ourCompany = "Our Company";
  exports.addressLine1 = "Address line 1,";
  exports.addressLine2 = "Address line 2,City";
  exports.Country = "Country";
  exports.Email = "Email";
  exports.Contact = "Contact";
  exports.CreditoreWeb = "CreditoreWeb";
  exports.DebtBuyer = "DebtBuyer";
  exports.Services = "Service";
  exports.Blog = "Blog";
  exports.Catalogue = "Catalogue";
  exports.Contact = "Contactus";
  exports.Terms = "Privacy";
  exports.Privacy = "Privacy";
  exports.EN = "en";
  exports.drawerListLanuage = {
    "en":{
        "dashboard":"Dashboard",
        "accountDetails":"Account Details",
        "businessProfile":"Business Profile",
        "notification":"Notification",
        "verificationRequest":"Verification Request",
        "myListings":"My Listings",
        "offers":"Offers",
        "plan":"Plan",
        "logOut":"Log Out",
        "Usermanagement": "User management",
        "VerificationRequests": "Verification Requests",
        "Listings": "Listings",
        "Collections": "Collections",
        "Documents": "Documents",
        "AuditLogs": "Audit Logs",
        "Plantransactions": "Plan transactions",
        "Search": "Search...",
        "logoutConfirmText": "Are you sure you want to log out of the account?",
        "Cancel": "Cancel",
        "Logout": "Log out"
    },
    "ar":{
        "dashboard":"لوحة القيادة",
        "accountDetails":"تفاصيل الحساب",
        "businessProfile":"الملف التجاري",
        "notification":"إشعار",
        "verificationRequest":"طلب التحقق",
        "myListings":"قوائمي",
        "offers":"عروض",
        "plan":"يخطط",
        "logOut":"تسجيل خروج",
        "Usermanagement": "إدارة المستخدمين",
        "VerificationRequests": "طلبات التحقق",
        "Listings": "قوائم",
        "Collections": "مجموعات",
        "Documents": "مستندات",
        "AuditLogs": "سجلات التدقيق",
        "Plantransactions": "المعاملات المخططة",
        "Search": "يبحث...",
        "logoutConfirmText": "هل أنت متأكد من أنك تريد تسجيل الخروج من الحساب ؟",
        "Cancel": "إلغاء",
        "Logout": "تسجيل الخروج"
    }
};

  // Customizable Area End